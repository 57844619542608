// main source
import './module/jquery';

import { Button, Collapse, Dropdown, Modal, Popover, ScrollSpy, Tooltip } from 'bootstrap';
import popperjs from '@popperjs/core';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import WOW from 'wow.js';

new WOW().init();

// js-modules
import './../../../js-modules/ajax';
import './../../../js-modules/scroller';
import './../../../js-modules/validate';
import './../../../js-modules/AjaxForm';
import './../../../js-modules/InputCopy';
import './../../../js-modules/URLParam';
import './../../../js-modules/share';
import './../../../js-modules/items';
import './../../../js-modules/modalNotify';

// modules
import './module/jquery.cookie';
import './module/theme_switcher';

// block
import './class/Header';
import './block/flipMenu';
import './block/search';
import './block/tooltip';